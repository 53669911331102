import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Home from './home'
import reportWebVitals from './reportWebVitals';
import { HashRouter, Routes, Route, Link } from 'react-router-dom'
import { registerMicroApps, start } from 'qiankun'

const root = ReactDOM.createRoot(document.getElementById('root'));

function RootDom() {

  return <>
    <Link to="/app">app</Link>&nbsp;&nbsp;&nbsp;&nbsp;
    <Link to="/home">home</Link>&nbsp;&nbsp;&nbsp;&nbsp;
    <Link to="/child-web">child</Link>
    <div id='child-app'></div>
    <Routes>
      <Route path="/app" element={<App />} />
      <Route path="/home" element={<Home />} />
    </Routes>
  </>
}

root.render(<HashRouter>
  <RootDom />
</HashRouter>);

const getActiveRule = (hash) => (location) => location.hash.startsWith(hash);

registerMicroApps([
  {
    name: 'child-app',
    entry: '../child/',
    container: '#child-app',
    activeRule: getActiveRule('#/child-web'),
  }
])

start()
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
